@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
body{
  font-family: "Montserrat", sans-serif;

}
@keyframes appear {
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}
.mainheader{
  display: flex;
  flex-direction: row;
  align-items: center;
  left: 0;
  right: 0;
  position: fixed;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  color: white;
  top: 0;
  height: 80px;
  z-index: 1;
  transition: 0.3s ease-in-out;


}
.mainheader-bg{
  background-color: black;
  transition: 0.3s ease-in-out;
  display: flex;
  flex-direction: row;
  align-items: center;
  left: 0;
  right: 0;
  position: fixed;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  color: white;
  top: 0;
  height: 80px;
  z-index: 1;
  transition: 0.3s ease-in-out;
}
.header-logo{
  width: 150px;
}
.fount-options{
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 50px;
  justify-content: space-between;
  cursor: pointer;
}
.option {
  position: relative;
  display: inline-block;
  color: white;
  text-decoration: none;
  transition: color 0.3s ease; /* Smooth transition for color change */
}

.option:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -3px; /* Adjust as needed */
  width: 0;
  height: 3px;
  background-color: white;
  transition: width 0.3s ease; /* Smooth transition for width change */
}

.option:hover {
 font-weight: bolder; /* Change the color on hover */
}

.option:hover:after {
  width: 100%; /* Expand the underline on hover */
}
.max{
  color: red;
  text-align: center;
}
.login{
  border: 2px solid white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 40px;
  border-radius: 5px;
  background: linear-gradient(to right, transparent 50%, black 50%);
  background-size: 200% 100%;
  transition: background-position 0.3s ease;
  cursor: pointer;
}
.login:hover{
  background-position: -100% 0;
  color: white; 
}
.login-bg{
  border: 2px solid white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 40px;
  border-radius: 5px;
  background: linear-gradient(to right, transparent 50%, white 50%);
  background-size: 200% 100%;
  transition: background-position 0.3s ease;
  cursor: pointer;
}
.login-bg:hover{
  background-position: -100% 0;
  color: black;
}
.backgroundImage{
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 800px;
  top: 0;
  object-fit: cover;
  z-index: -1;


}
.background{
position: absolute;
left: 0;
top: 0;
width: 100%;
right: 0;
height: 800px;
object-fit: cover;
z-index: -1;
filter: brightness(0.4);


}
.landing-info{
  display: flex;
  flex-direction: column;
  padding-left: 150px;
  height: 700px;
  justify-content: center;
}
.slogan{
  font-size: 150px;
  font-style: italic;
  color: white;

}
.info{
  color: white;
  font-size: 20px;
  width: 800px;
  line-height: 45px;
  margin-top: -100px;
  padding-left: 100px;

}
.action-button{
  padding-left: 100px;
  margin-top: 40px;
}
.view-all-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 20px;
  font-size: 25px;
  height: 70px;
  padding-right: 30px;
  padding-left: 30px;
  font-family: "Montserrat", sans-serif;
  border-radius: 100px;
  border: none;
  cursor: pointer;
  background: linear-gradient(to right, white 50%, black 50%);
  background-size: 200% 100%;
  transition: background-position 0.3s ease;
  color: black; /* Default text color */
}

.view-all-button:hover {
  background-position: -100% 0;
  color: white; /* Text color on hover */
}

.right-arrow {
  fill: black; /* Default arrow color */
  width: 34px;
  height: 24px;
}

.view-all-button:hover .right-arrow {
  fill: white; /* Arrow color on hover */
}
.about-us-page{
  padding-top: 150px;
  padding-bottom: 150px;
  padding-left: 20px;
}
.bold{
  font-weight: bold;
}
.about-us{
  font-weight:300;
  font-size: 80px;
  width: 800px;
 
}

.flag{
  width: 30px;
  height: 30px;
}
.milestones{
  display: flex;
  flex-direction: column;
  margin-top: -30px;


}
.milestone-title{
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 20px;
}
.milestones-info{
  width: 600px;
  margin-top: -10px;
}
.about-us-page{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.gordon-family{
  width: 500px;
  border: 1px solid rgb(233, 233, 233);

}
.family{
  width: 500px;
  height: 400px;
  object-fit: cover;
}
.about-text{
  padding-left: 10px;
  font-size: 18px;
  line-height: 30px;

}
.whatwedo{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 100px;
}
.whatwedo-info{
  display: flex;
  flex-direction: column;
  align-content: center;
}
.whatwedo-title{
  text-align: center;
  font-weight: lighter;
  font-size: 50px;
}
.whatwedo-slogan{
  font-size: 20px;
  width: 900px;
  margin-top: -20px;
  line-height: 40px;
  text-align: center;
}
.whatwedo-additional-info{
  display: flex;
  flex-direction: row;
  column-gap: 150px;
  align-items: center;
  margin-top: 50px;
}
.whatwedo-box{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 250px;
  border: 1px solid #BABABA;
  padding-left: 10px;
  padding-right: 10px;
  height: 300px;
  background-color: white;
}
.whatwedo-box-info{
  font-size: 14px;
}
.lowerbackground{
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  height: 300px;
  margin-top: 400px;
  z-index: -1;
  object-fit: cover;
  filter: brightness(0.5);
}
.ourproducts{
  padding-top: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 150px;
}
.ourproducts-title{
  font-size: 50px;
  font-weight: lighter;

}
.ourproducts-box{
  display: flex;
  column-gap: 100px;
  flex-direction: row;
}
.scroll{
  display: flex;
  flex-direction: column;
  row-gap: 100px;
  width: 800px;
  height: 500px;
}
.scroll::-webkit-scrollbar {
  display: none;
}
.ourproducts-product-info-box{
  width: 350px;
}
.product-title{
  font-weight: lighter;
}
.outline-div{
  background-color: #E9E7E7;
  border-radius: 0px 0px 150px 150px;
  margin-top: 50px;
  width: 350px;
  height: 280px;
  z-index: -1;
  position: relative;
}
.bold-small{
  font-size: 20px;
  font-weight: bold;
}
.productimage{
  width: 400px;
  position: absolute;
  margin-top: -100px;
  margin-left: -20px;
}
.product-info{
  line-height: 40px;
  font-size: 15px;
}
.purchase-buttons{
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 20px;
  margin-top: 20px;
}
.buynow{
  background-color: black;
  font-size: 18px;
  width: 200px;
  height: 40px;
  color: white;
  border: none;
  border-radius: 100px;
  cursor: pointer;

}
.addtocart{
  background-color: white;
  font-size: 18px;
  width: 200px;
  height: 40px;
  color: black;
  border: 1px solid black;
  border-radius: 100px;
  cursor: pointer;
}
.stars{
  width: 200px;
}
.customer{
  width: 350px;
  margin-top: 30px;
  height: 500;
  object-fit: cover;
}
.testimonials{
  display: flex;
  flex-direction: column;
  padding-top: 100px;
  padding-left: 20px;
}
.titleone{
  font-weight: lighter;
  margin-bottom: -20px;
}
.test-slogan{
  font-weight: lighter;
  margin-top: -10px;
}
.testimonials-box{
  width: 700px;
  height: 400px;
  align-items: center;
  display: flex;
  column-gap: 60px;
  flex-direction: row;
  border: 2px solid #BABABA;
  background-color: white;

}
.text-side{
  width: 500px;
  padding-left: 20px;
}



.image-side{
  width: 350px;
  height: 400px;
}
.testimonial-background{
  position: absolute;
  width: 800px;
  margin-top: -80px;
  z-index: -1;
  height: 800px;
  object-fit: cover;
  filter: brightness(0.6);
  right: 0;
}
.review{
  opacity: 0;
  position: absolute;
  margin-top: -150px;
  width: 250px;
  transition: opacity 1s ease-in-out;
}
.customer{
  margin-top: 20px;
  height: 400px;
  object-fit: contain;
}
.images{
  position: absolute;
  margin-left: -71px;
  height: 350px;
  margin-top: -20px;
  opacity: 0;
  transition: opacity 1s ease-in-out;

}
.showing{
  opacity: 1;
}
.showimages{
  opacity: 1;
}
.contact-us{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 250px;
  padding-bottom: 60px;
}
.contact-title{
  color: white;
  font-weight: lighter;
  font-size: 50px;
}
.contact-slogan{
  font-weight: lighter;
  color: white;
  font-size: 30px;
  width: 500px;
  margin-top: -20px;
  text-align: center;
}
.contact-form{
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  align-items: center;
}
.name{
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 20px;
}
.name-input{
  text-align: center;
  color: grey;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  border: none;
  padding-bottom: 10px;
  border-bottom: 5px solid black;
  outline: none !important;

}
.email{
  text-align: center;
  color: grey;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  border: none;
  padding-bottom: 10px;
  border-bottom: 5px solid black;
  width: 450px;
  outline: none !important;

}
.textarea{
  text-align: center;
  color: grey;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  border: none;
  padding-bottom: 10px;
  border-bottom: 5px solid black;
  width: 450px;
  height: 100px;
  outline: none !important;


}


.contact-background{
  position: absolute;
  left: 0;
  right: 0;
  height: 200px;
  z-index: -1;
  filter: brightness(0.5);
  width: 100%;
  object-fit: cover;
}
.contact-footer{
  margin-top: 60px;
  color: grey;
}
.social-media-images{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 20px;
}
.submit-button{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 250px;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  border-radius: 100px;
  background-color: white;
  border: 1px solid black;
  cursor: pointer;
  color: black;
}
.social{
  width: 30px;
}
.signup-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: appear 1s ease-in-out;
}
.top-slogan{
  display: flex;
  flex-direction: column;
  align-items: baseline;
  padding-left: 20px;
  width: 100%;
}
.signup-title{
  font-size: 50px;
  width: 500px;
  font-weight: lighter;
}
.signup-slogan{
  margin-top: -20px;
  font-size: 20px;
  width: 500px;
}
.signup-form{
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;
  color: white;
  width: 450px;
  height: 500px;
  border-radius: 30px;
}
.input-field{
  display: flex;
  flex-direction: column;
  align-items: baseline;
  width: 80%;
  padding-left: 30px;
}
.signup{
  margin-top: 20px;
}
.label{
  font-size: 20px;
  margin-top: 10px;
}
.input-signup{
  height: 35px;
  width: 80%;
  margin-top: 10px;
  border: 1px solid white;
  background-color: transparent;
  border-radius: 10px;
  color: white;
  font-size: 17px;
  font-family: "Montserrat", sans-serif;
  padding-left: 20px;
}
.signup-button{
  margin-top: 20px;
  width: 250px;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 40px;
  border-radius: 100px;
  background-color: white;
  color: black;
  cursor: pointer;
  border: none;
}
.account-already{
  margin-top: 20px;
  text-decoration: underline;
  margin-bottom: -5px;
}
.guest{
  text-decoration: underline;

}
.jar-background{
  position: absolute;
  right: 0;
  width: 50%;
  height: 100%;
  object-fit: cover;
  filter: brightness(0.7);
  z-index: -1;
  top: 0;
}
.bar{
  width: 100%;
  margin-top: 10px;
  height: 1px;
  background-color: #BABABA;
  position: relative;
}
.or{
  position: absolute;
  background-color: black;
  bottom: 60px;
  left: 45%;
  height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40px;
  
}
.guest-continue{
  margin-top: 20px;
  width: 250px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid white;
  height: 50px;
  cursor: pointer;
  border-radius: 100px;
}
.buypage{
  padding-top: 100px;
  padding-left: 20px;
  display: flex;
  flex-direction: row;
  align-items: top;
  justify-content: center;
  padding-bottom: 100px;
}
.buy-title{
  font-size: 50px;
  font-weight: lighter;
  margin-bottom: -10px;
}
.buy-slogan{
  font-size: 18px;
}

.cart{
  width: 500px;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
}
.product-added{
display: flex;
flex-direction: column;
align-items: center;
margin-top: 40px;
}
.image-cover{
  background-color: #bababa95;
  position: relative;
  height: 120px;
  width: 200px ;
  border-radius: 0px 0px 70px 70px;
}
.added-product-image{
  width: 200px;
  position: absolute;
  margin-top: -50px;
}
.added-product-price{
  margin-top: -10px;
  font-size: 20px;
}
.adjustable-buttons{
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 50px;
}
.reduce{
  background-color: black;
  color: white;
  width: 30px;
  height: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  cursor: pointer;
}
.add{
  background-color: black;
  color: white;
  width: 30px;
  height: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  cursor: pointer;
}
.order-ammount{
  font-weight: lighter;
}
.cancel-order{
  width: 500px;
  margin-top: 50px;
  text-align: center;
}
.your-cart{
  height: 400px;
}
.your-order{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 500px;
  height: 600px;
  border: none;
  border-left: 1px solid #BABABA;
}
.order-title{
  font-weight: lighter;
  font-size: 40px;
}
.product-price{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.titles{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 90%;
  justify-content: space-between;
}
.products-added{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  height: 100px;
  overflow-y: scroll;  
}
.products-added::-webkit-scrollbar {
  display: none;
}
.product-added-information{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  color: #BABABA;
  justify-content: space-between;
}
.product-added-quantity{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  color: #BABABA;
  justify-content: space-between;
}
.product-added-cost{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 95%;
  color: #BABABA;
  justify-content: space-between;
}
.product-shipping-cost{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 95%;
  color: #BABABA;
  justify-content: space-between;
  border: none;
  border-top: 1px solid #BABABA;
}
.product-total-cost{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 95%;
  color: #BABABA;
  justify-content: space-between;
}
.delivery-form{
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  align-items: center;
}
.input-address{
  font-family: "Montserrat", sans-serif;
  color: black;
  font-size: 15px;
  margin-top: 10px;
  height:30px;
  width: 400px;
  border: 1px solid #BABABA;
  border-radius: 10px;
  text-align: center;

}

.city-address{
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
}
.input-address-short{
  font-family: "Montserrat", sans-serif;
  color: black;
  font-size: 15px;
  margin-top: 10px;
  margin-right: 5px;
  height:30px;
  width: 195px;
  border: 1px solid #BABABA;
  border-radius: 10px;
  text-align: center;
}
.continue-to-payment{
  width: 400px;
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50px;
  border-radius: 100px;
  border: none;
  color: white;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  cursor: pointer;
}
.total{
  color: black;
  font-size: 20px;
}
.total-price{
  color: black;
  font-weight: bold;
  font-size: 20px;
}
.payment-form{
  top: 100px;
  position: absolute;
  width: 500px;
  right: 40%;
  left: 40%;
  padding-right: 140px;
}
.pay-now{
  margin-top: 20px;
  width: 100%;
  height: 50px;
  background-color: black;
  color: white;
  border-radius: 10px;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;

}
.product-bought{
  height: 600px;
  margin-top: 100px;
  width: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.buy-background{
  width: 55%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  object-fit: cover;
  filter: brightness(0.7);
}
.lighter{
  font-weight: lighter;
}
.payment-info{
  width: 500px;
  text-align: center;
  font-size: 15px;
  color: #BABABA;
}
.grand{
  text-align: center;
  font-size: 25px;
}
.link{
  text-decoration: none;
  cursor: pointer;
  color: white;
}
.complete{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 100px;
}
.checkmark{
  width: 250px;
}
.thanks{
  font-weight: lighter;
  font-size: 50px;
  margin-bottom: -10px;
}
.order{
  margin-bottom: -10px;
  width: 400px;
  text-align: center;
}
.product-page{
  padding-top: 120px;
  padding-left: 150px;

}
.container{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 500px;
  overflow-y: scroll;
}
.transaction-container{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 150px;  
}
.column{
  width: 200px;
  text-emphasis: center;
  background-color: black;
  color: white;
  height: 50px;

}
.row{
  width: 200px;
  background-color: #bababa38;
  text-align: center;
  font-size: 15px;
}
.backendHeader{
  display: flex;
  flex-direction: row;
  position: fixed;
  background-color: white;
  top: 0;
  left: 0;
  right: 0;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: 1px solid #BABABA;

}
.left{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.noah{
  margin-bottom: -10px;
}
.welcome{
  margin-left: 20px;
  padding-left: 10px;
  border: none;
  border-left: 1px solid #BABABA;
}
.logo-top{
  width: 100px;
}
.logout-button{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 150px;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  border-radius: 5px;
  border: 1px solid #BABABA;
  background-color: transparent;
  color: #BABABA;
  cursor: pointer;

}
.backendNavbar{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 140px;
  border-right: 1px solid #BABABA;
  position: fixed;
  left: 0;
  bottom: 0;
  top: 100px;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
}
.upper{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 50px;
  color: #BABABA;
}
.options-upper{
  color: grey;
  text-decoration: none;
}
.options-upper:hover{
  color:  black;
  cursor: pointer;
}
.lower{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 20px;
  color: #BABABA;
}
.create-new{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 50px;
  padding-right: 100px;
}
.add-product{
  background-color: black;
  font-size: 18px;
  display: flex;
  color: white;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 200px;
  border-radius: 10px;
  border: none;
}
.product-info-list{
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 50px;
}
.set{
  display: flex;
  flex-direction: column;
  
}
.product-input{
  background-color: #bababa90;
  text-align: center;
  width: 250px;
  height: 40px;
  border-radius: 10px;
  border: none;
  font-size: 18px;

}
.product-info-two{
  margin-top: 20px;
}
.product-info-three{
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.product-text{
  background-color: #bababa90;
  text-align: center;
  width: 500px;
  height: 200px;
  border-radius: 10px;
  border: none;
  font-size: 18px;
}
.create{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}
.cancel{
  color: red;
  text-decoration: underline;
  cursor: pointer;
}
.link-new{
  color: black;
}
.image-upload-div{
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
.image-upload{
  margin-top: 20px;
}
.shipping-status{
  margin-bottom: 20px;
}
.select-container{
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 40px;
  border: 1px solid black;
  border-radius: 5px;
  margin-bottom: 20px;

}
.status{
  border: none;
  appearance: none;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  outline: none !important;
}
.tracking{
  font-size: 20px;
  text-align: center;
  width: 250px;
  font-family: "Montserrat", sans-serif;
  border: 1px solid black;
  padding: 5px;
  border-radius: 5px;
}
.table-transaction{
  display: flex;
  flex-direction: column;
  
}
.table-header{
  display: flex;
  flex-direction: row;
  justify-content: center;
  column-gap: 50px;
  background-color: black;
  color: white;
  width: 1000px;
  text-align: center;

}
.table-info{
  display: flex;
  width: 1000px;
  flex-direction: row;
  text-decoration: none;
  color: black;
  text-align: center;
  column-gap: 50px;
  justify-content: center;
}
.table-stuff{
  width: 220px;  
}
.table-stuff-low{
  width: 220px;
  overflow: hidden;
  cursor: pointer;
  text-overflow:ellipsis;
}
.red {
  background-color: red;
  display: flex;
  width: 1000px;
  flex-direction: row;
  text-align: center;
  column-gap: 50px;
  justify-content: center;
}

.yellow {
  background-color: yellow;
  display: flex;
  width: 1000px;
  flex-direction: row;
  text-align: center;
  column-gap: 50px;
  justify-content: center;
}

.green {
  background-color: green;
  display: flex;
  width: 1000px;
  flex-direction: row;
  text-align: center;
  column-gap: 50px;
  justify-content: center;
}
.error{
  color: red;
}
.success{
  color: green;
}
.message-actual{
  width: 400px;
}
.reply-form{
  display: flex;
  flex-direction: column;
  width: 500px;
}
.form-label{
  font-weight: bold;
}
.mobile-landing{
  background: rgb(195,34,89);
background: linear-gradient(0deg, rgb(232, 61, 61) 0%, rgba(253,142,45,1) 100%);
width: 100%;
position: absolute;
left: 0;
padding-bottom: 100px;
right: 0;

}
.mobile-purchasing{
  width: 100%;
  padding-top: 80px;
  padding-bottom: 20px;
}
.circular-circle{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px
}
.circle-mobile{
  background-color: rgb(196, 196, 196);
  height: 200px;
  width: 200px;
  border-radius: 400px;
  position: relative;
}
.add-decrease{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 60px;
}
.rundown{
display: flex;
flex-direction: column;
justify-content: center;
width: 100%;
}
.mobile-buy-button-div{
 margin-top: 20px;
 width: 100%;
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: center;
}
.mobile-buy-button{
  font-size: 15px;
  width: 200px;
  color: white;
  background-color: black;
  height: 50px;
  border: none;
  border-radius: 10px;
  font-family: "Montserrat", sans-serif;

}
.rundown-amount{
  display: flex;
  flex-direction: row;
  padding-left: 20px;
  width: 90%;
  justify-content: space-between;
  border-bottom: 1px solid #BABABA;
}
.add-mobile, .decrease-mobile{
background-color: black;
color: white;
width: 50px;
height: 50px;
border-radius: 10px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
font-size: 30px;
font-weight: bolder;
}

.product-mobile-open{
  width: 260px;
  position: absolute;
}
.product-mobile-title{
  text-align: center;
}
.our-product-mobile-div{
  width: 100%;
  text-align: center;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.testimony-mobile-div{
  width: 100%;
  text-align: center;
  padding-top: 80px;
}
.jar-mobile{
  width: 250px;
}
.test{
  text-align: start;
  padding-left: 10px;
  opacity: 0;
}
.mobile-header{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  z-index: 3;
  left: 0;
  right: 0;
  top: 0;
  height: 80px;
  background-color: white;
}
.info-mobile-pro{
  text-align: start;
  padding-left: 20px;
  width: 80%;
}
.mobile-buy{
  width: 200px;
  height: 50px;
  font-size: 20px;
  color: white;
  background-color: black;
  border: none;
  border-radius: 10px;
  font-family: "Montserrat", sans-serif;

}

.title{
  padding-top: 100px;
  width: 100%;
  text-align: center;
  font-size: 30px;
}
.text-mobile{
  font-size: 20px;
  width: 100%;
  text-align: center;
  margin-top: -20px;
}
.buy-button-mobile-div{
  width: 100%;
  text-align: center;

}
.buy-button-mobile{
  height: 60px;
  width: 220px;
  color: white;
  background-color: black;
  font-size: 20px;
  border: none;
  border-radius: 10px;
  margin-top: 20px;
  font-family: "Montserrat", sans-serif;

}

.logo-mobile-png{
  width: 100px;
}
.quote{
  width: 30px;
}
.milestones-div{
  margin-top: 100px;
  width: 100%;
  display: flex;
  flex-direction: column;

}
.flag-new{
  width: 30px;

}

.subject{
height: 30px;
border: 1px solid black;
border-radius: 5px;
margin-top: 10px;
margin-bottom: 10px;
font-size: 18px;
font-family: "Montserrat", sans-serif;
text-align: center;


}
.body{
  margin-top: 10px;
  height: 200px;
  border-radius: 5px;
  border: 1px solid black;
  font-size: 18px;
  padding: 5px;
  font-family: "Montserrat", sans-serif;

}
.button-div{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.send-button{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 250px;
  margin-top: 20px;
  font-size: 17px;
  font-family: "Montserrat", sans-serif;
  background-color: black;
  color: white;
  border-radius: 100px;
  border: none;

}
@media screen and (max-width:1000px){
  .slogan{
    font-size: 80px;
  }
  .info{
    line-height: 35px;
    margin-top: -40px;
    margin-left: -100px;
  }
  .view-all-button{
    margin-left: -100px;
  }
  .about-us-page{
    flex-direction: column;
  }
  .gordon-family{
    margin-left: -300px;
  }
  .whatwedo-additional-info{
    column-gap: 50px;
  }
  .testimonial-background{
    display: none;
  }
}
@media screen and (max-width:900px){
  .slogan{
    font-size: 80px;
    margin-left: -50px;
    width: 500px;
  }
  .info{
    line-height: 35px;
    margin-top: -40px;
    margin-left: -100px;
    width: 500px;

  }
  .action-button{
    width: 500px;
    margin-left: -50px;


  }
  .whatwedo-slogan{
    width: 100%;
  }
  .fount-options{
    display: none;
  }
  .view-all-button{
    margin-left: -100px;
  }
  .about-us-page{
    flex-direction: column;
  }
  .gordon-family{
    margin-left: -300px;
  }
  .whatwedo-additional-info{
    flex-direction: column;
    row-gap: 20px;
  }
  .lowerbackground{
    height: 700px;
  }
  .testimonial-background{
    display: none;
  }
}
@media screen and (max-width:800px){
  .slogan{
    font-size: 80px;
    margin-left: -50px;
    width: 500px;
  }
  .info{
    line-height: 35px;
    margin-top: -40px;
    margin-left: -100px;
    width: 500px;

  }
  .action-button{
    width: 500px;
    margin-left: -50px;


  }
  .about-us{
    font-size: 60px;
    width: 100%;
  }
  .gordon-family{
    margin-left: 0px;
  }
  .whatwedo-slogan{
    width: 100%;
  }
  .fount-options{
    display: none;
  }
  .view-all-button{
    margin-left: -100px;
  }
  .about-us-page{
    flex-direction: column;
    padding-left: 100px;
  }
 
  .whatwedo-additional-info{
    flex-direction: column;
    row-gap: 20px;
  }
  .lowerbackground{
    height: 700px;
  }
  .ourproducts-box{
    flex-direction: column;
    width: 750px;
  }
  .ourproducts{
    width: 100%;
  }
  .ourproducts-image-box{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .product-title{
    width: 100%;
    text-align: center;
  }
  .ourproducts-product-info-box{
    width: 100%;
  }
  .product-info{
    display: none;
  }
  .purchase-buttons{
    flex-direction: column;
    row-gap: 20px;
  }
  .testimonial-background{
    display: none;
  }
  .testimonials{
    width: 100%;

  }
  .testimonials-box{
    border: none;
    column-gap: 0px;
    width: 650px;
  }
  .titleone{
    width: 200px 
  }
  .titletwo{
    width: 300px;
  }
  .test-slogan{
    width: 400px;
  }
  .contact-us{
    width: 100%;
  }
}
@media screen and (max-width:700px){
  .slogan{
    font-size: 80px;
    margin-left: -100px;
    width: 500px;
  }
  .info{
    line-height: 35px;
    margin-top: -40px;
    margin-left: -200px;
    width: 500px;

  }
  .action-button{
    width: 500px;
    margin-left: -100px;


  }
  .about-us{
    font-size: 40px;
    width: 100%;
  }
  .gordon-family{
    margin-left: -100px;
  }
  .whatwedo-slogan{
    width: 100%;
  }
  .fount-options{
    display: none;
  }
  .view-all-button{
    margin-left: -100px;
  }
  .about-us-page{
    flex-direction: column;
  }
  .about-us{
    margin-left: -100px;
  }
  .gordon-family{
    margin-left: -150px;
  }
  .milestones{
    margin-left: -100px;
    width: 500px;
  }
  .milestones-info{
    width: 500px;
  }
 
  .whatwedo-additional-info{
    flex-direction: column;
    row-gap: 20px;
  
  }
  .lowerbackground{
    height: 700px;
  }
  .ourproducts-box{
    flex-direction: column;
    width: 750px;
  }
  .ourproducts{
    width: 100%;
  }
  .ourproducts-image-box{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .product-title{
    width: 100%;
    text-align: center;
  }
  .ourproducts-product-info-box{
    width: 100%;
  }
  .product-info{
    display: none;
  }
  .purchase-buttons{
    flex-direction: column;
    row-gap: 20px;
  }
  .testimonial-background{
    display: none;
  }
  .testimonials{
    width: 100%;

  }
  .testimonials-box{
    border: none;
    column-gap: 0px;
    width: 100%;
    
  }
  .contact-footer{
    width: 100%;
    text-align: center;
  }
  .titleone{
    width: 200px 
  }
  .titletwo{
    width: 300px;
  }
  .test-slogan{
    width: 400px;
  }
  .contact-us{
    width: 100%;
  }
}
